  html, body {
    margin: 0;
    padding: 0;
    height: auto;
    box-sizing: border-box;
  }

  .home {
    box-sizing: border-box;
    margin: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .header {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 4vh;
    padding-right: 5vw;
    padding-left: 5vw;
    margin-top: 3vw;
    margin-right: auto;
    margin-bottom: 2vw;
    margin-left: auto;
    font-family: 'Chivo', sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: #aaa;
    line-height: 1.8em;
    letter-spacing: .02em;
    opacity: 1;
    transition: opacity 140ms cubic-bezier(.55,.06,.68,.19);
    
  }

  .header-logo {
    font-family: 'Hind Siliguri', sans-serif;
    color: black;
    font-size: 3.25rem;
    font-weight: 900;
    margin-right: 2rem;
  }

  .welcome-section {
    position: relative;
    box-sizing: border-box;
    font-family: 'Hind Siliguri', sans-serif;
    background: radial-gradient(circle at center, #EEE9E3, #e9e3dd, #EEE9E3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    width: 100%;
    height: 40vh;
    margin-right: 5vw;
    margin-bottom: auto;
    margin-left: auto;
}
.welcome-message {
  position: absolute;
  top: 50%;  /* vertically center it */
  left: 75%;  /* horizontally center it */
  transform: translate(-50%, -50%);  /* ensures true centering */
  z-index: 1;  /* ensure it's above the image */
  color: black; 
  width: 25%;
  font-size: 1em;
  background-color: rgba(255, 255, 255, 0.7);  /* semi-transparent white */
  padding: 10px;  /* optional: some padding around the text */
  border-radius: 5px;  /* optional: rounded corners */
}

.welcome-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center 55%; 
}




  .download-button {
    box-sizing: border-box;
    background: white;
    border: 1.5px solid red;
    color: red;
    padding: 10px 40px;
    font-size: 1.5em;
    cursor: pointer;
    font-family: 'Hind Siliguri', sans-serif;
    transition: background 0.3s;
    width: 20rem;
    height: 5rem;
  }

  .project-card {
    position: relative;
    box-sizing: border-box;
    color:black;  
    font-size: 1.1em;
    font-family: 'Hind Siliguri', sans-serif;
    transition: background 0.3s;
    width: 40%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
  }
  .project-card::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity .5s ease;
}

.project-card:hover::after {
    opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .5s ease;
  z-index: 2;
  opacity: 0;
}



.project-card:hover .overlay {
  opacity: 1;
}

.project-card::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity .5s ease;
  z-index: 1; /* so that it's below the overlay */
}

.project-card:hover::after {
  opacity: 1;
}

  .download-button:hover {
    background: red;
    color: white;
  }

  .project-card img {
    width: 100%;
    height: auto;
  }

  .project-card h2 {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    margin: 0;
  }

  .download-button {
    margin-top: auto;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.5s;
  }

  .project-button{
    margin-top: 0;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s;
    color: white;
    font-size: 1.3em;
    font-family: 'Hind Siliguri', sans-serif;
    width: 10rem;
    height: 2.5rem;
    padding: 10px; /* Adjust this as needed */
  }

  .resume-bar {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    padding-right: 5vw;
    padding-left: 5vw;
    margin-top: 1vw;
    margin-right: auto;
    margin-bottom: 1vw;
    margin-left: auto;
    font-family: 'Chivo', sans-serif;
    font-weight: 400;
    font-size: 13px;
  }

  .icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    width: 100%;
    padding: 10px 0;
    grid-gap: 4rem;
  }

  .icon {
    width: 70%;
    height: auto;
  }

  .project-container {
    display: flex;
    align-items: stretch; 
    justify-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 0;
    grid-gap: 4rem;
    
  }
  

  
  .work-history {
    display: grid;
    align-items: stretch; 
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    width: 60%;  
    padding: 10px 0;
    grid-gap: 1rem;
    

    
    text-rendering: optimizeLegibility;
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 1.6em;
    letter-spacing: .03em;
    text-transform: none;
    color: #333;
    margin-top: 0;
    white-space: pre-wrap;
  }
  
  .resume-menu-bar {
    box-sizing: border-box;
    display: grid;
    width: 100%;
    height: 8vh;
    
    margin-top: 1vw;
    margin-right: auto;
    margin-bottom: 1vw;
    margin-left: auto;
    font-family: 'Chivo', sans-serif;
    font-weight: 400;
    font-size: 13px;
    justify-items: center;
    align-items: center;
  }
  .resume-menu {
    text-decoration: none;
    color: inherit;
    display: grid;
    align-items: stretch; 
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    width: 60%;  
    padding: 10px 0;
    grid-gap: 1rem;
    

    
    text-rendering: optimizeLegibility;
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 1.6em;
    letter-spacing: .03em;
    text-transform: none;
    color: rgb(222, 222, 225);;
    margin-top: 0;
    white-space: pre-wrap;
  }

  .resume-menu a {
    text-decoration: none;
    color: inherit;
}
  .project-bar {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-right: 5vw;
    padding-left: 5vw;
    margin-top: 3vw;
    margin-right: auto;
    margin-bottom: 2vw;
    margin-left: auto;
    font-family: 'Chivo', sans-serif;
    font-weight: 400;
    font-size: 13px;
  }

  .work-history-bar {
    box-sizing: border-box;
    display: grid;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 50vh;
    font-family: 'Chivo', sans-serif;
    font-weight: 400;
    font-size: 13px;
  }

  .myRole {
    grid-column: span 2;
  }


  .resume-menu a.active {
    color: black;
    font-weight: bolder;
}
  
@media (max-width: 768px) {
  /* Add mobile-specific styles here */
  /* For example, you can modify font sizes, padding, margins, etc. for better mobile display */
  .work-history{
    display: flex;
    flex-direction: column;
  }
  .welcome-message{
    font-size: 1em;
    width: 80%;
    top: 20%;  /* vertically center it */
    left: 50%;  /* horizontally center it */
    transform: translate(-50%, -50%);  /* ensures true centering */
  }

  .home{
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .header {
    display: flex;
    flex-direction: column;
    height: 10vh;
  }
  .resume-bar {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

    .project-container {
      display: flex;
      flex-direction: column;
      position: relative;
      transition: background-color 0.3s ease; 
    }

  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    width: 100%;
    grid-gap: 1rem;
  }
  .download-button {
    font-size: 1em;
    width: 15rem;
    height: 3rem;
  }
  .project-card {
    font-size: 1em;
    width: 100%;
    opacity: 1;
  }
  .resume-menu{
    width: 100%;
  }

  .icon {
    width: 100%;
    height: auto;
  }

  .header-logo {
    margin-bottom: .5rem;
    margin-top: .5rem;
  }
  .project-bar {
    width: 80%;}
}
  